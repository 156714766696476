import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Card, Row, Col, Spin, Tag } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import { replaceMarkdownImages } from "./helpers";
import { API } from "./constants";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Title from "antd/es/typography/Title";
import { DateTime } from "luxon";

const { Search } = Input;

const Events = () => {
  const { marker } = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [events, setEvents] = useState({
    ongoing: [],
    upcoming: [],
    archive: [],
  });
  const [activeTab, setActiveTab] = useState("ongoing");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showOnlyMyEvents, setShowOnlyMyEvents] = useState(false);

  const handleSwitchChange = (checked) => {
    setShowOnlyMyEvents(checked); // Update the state based on the switch value
    console.log("Switch value:", checked);
  };

  // Fetch events from the API
  const fetchEvents = async (page, section, marker, searchTerm) => {
    try {

      const now = DateTime.now().setZone("Europe/Amsterdam"); // Adjust to your server's timezone

      const currentDate = now.toFormat("yyyy-MM-dd"); 
      const currentTime = now.toFormat("HH:mm:ss.SSS");

      const sortOrder = section === "archive" ? "endDate:desc,endTime:desc" : "startDate:asc,startTime:asc";
        
      let timeFilter = "" 
      if(section === "archive") {
        // Past events
        timeFilter = `_where[_or][0][endDate_lt]=${currentDate}` +
        `&_where[_or][1][_and][0][endDate]=${currentDate}` +
        `&_where[_or][1][_and][1][endTime_lt]=${currentTime}`;
      } 
      
      if(section === "ongoing") {
        // Ongoing events
        timeFilter = `_where[_or][0][_and][0][startDate_lt]=${currentDate}` +
        `&_where[_or][0][_and][1][endDate_gt]=${currentDate}` +

        `&_where[_or][1][_and][0][startDate_lt]=${currentDate}` +
        `&_where[_or][1][_and][1][endDate]=${currentDate}` +
        `&_where[_or][1][_and][2][endTime_gte]=${currentTime}` +

        `&_where[_or][2][_and][0][endDate_gt]=${currentDate}` +
        `&_where[_or][2][_and][1][startDate]=${currentDate}` +
        `&_where[_or][2][_and][2][startTime_lte]=${currentTime}` +

        `&_where[_or][3][_and][0][endDate]=${currentDate}` +
        `&_where[_or][3][_and][1][endTime_gte]=${currentTime}` +
        `&_where[_or][3][_and][2][startDate]=${currentDate}` +
        `&_where[_or][3][_and][3][startTime_lte]=${currentTime}`;
      } 
      
      const markerFilter=marker?`&_where[marker]=${marker}`:'';

      if(section === "upcoming") {
        // Upcoming events
        timeFilter = `_where[_or][0][startDate_gt]=${currentDate}` +
        `&_where[_or][1][_and][0][startDate]=${currentDate}` +
        `&_where[_or][1][_and][1][startTime_gte]=${currentTime}`;
      } 
      
      const response = await axios.get(`${API}/events?${timeFilter}${markerFilter}`, {
        params: {
          _sort: sortOrder,
          _limit: 10,
          _start: (page - 1) * 10,
          ...(searchTerm && { _q: searchTerm }),
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching events:", error);
      return [];
    }
  };

  // Load initial events and handle search
  useEffect(() => {
    (async () => {
      const ongoing = await fetchEvents(1, 'ongoing', marker, searchTerm);
      const upcoming = await fetchEvents(1, 'upcoming', marker, searchTerm);
      const archive = await fetchEvents(1, 'archive', marker, searchTerm);
      setEvents((prev) => ({
        ...prev,
        ['ongoing']: ongoing
        .filter((obj, index, self) =>
          index === self.findIndex((item) => item.id === obj.id)),
      }));
      setPage(2);
      setEvents((prev) => ({
        ...prev,
        ['upcoming']: upcoming
        .filter((obj, index, self) =>
          index === self.findIndex((item) => item.id === obj.id)),
      }));
      setPage(2);
      setEvents((prev) => ({
        ...prev,
        ['archive']: archive
        .filter((obj, index, self) =>
          index === self.findIndex((item) => item.id === obj.id)),
      }));
      setPage(2);
    })();
  }, [searchTerm]);

  // Load more events for infinite scroll
  const loadMoreEvents = async () => {
    const newEvents = await fetchEvents(page, activeTab, marker, searchTerm);
    setEvents((prev) => ({
      ...prev,
      [activeTab]: [...prev[activeTab], ...newEvents].filter((obj, index, self) =>
        index === self.findIndex((item) => item.id === obj.id))
      
      ,
    }));
    setPage((prev) => prev + 1);

    if (newEvents.length < 10) {
      setHasMore(false);
    }
  };

  // Render Event Cards
  const renderEventCards = (eventList) =>
    (eventList.length>0)?eventList.map((event) => { 
      const start = dayjs(`${event?.startDate}T${event?.startTime}`, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD MMMM YYYY, HH:mm A");
      const end = dayjs(`${event?.endDate}T${event?.endTime}`, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD MMMM YYYY, HH:mm A");
      const description =  replaceMarkdownImages(event.summary?event.summary:'', false);

      const imageUrl = event.image?.formats?.medium?.url
      ? `${API}${event.image.formats.medium.url}`
      : null;
      
      return ( <Col key={event.id} xs={24} sm={12} md={8} lg={6}>
        <div
          className="event-card-wrapper"
          style={{
            marginBottom: 16,
            overflow: "hidden", // Ensures content stays inside card boundaries
          }}
        >
          <Helmet>
            
          </Helmet>
        <Card
          hoverable
          style={{
            marginBottom: 16,
            backgroundImage: imageUrl
              ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url(${imageUrl})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "#fff",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)", // Improves text readability
            transition: "background-image 0.3s ease", // Smooth transition effect           

          }}
          styles={{body:{
            backgroundColor: "transparent",
            padding: "16px",
          }}}
          onClick={() => window.location=`/event/${event.id}`}
        >
          <h3>{event.title || "Event"}</h3>
          <p>
          Start: {start}<br/>
          End: {end}

          </p>
          <p>{description || "No description available"}</p>
        </Card>
        </div>
      </Col>)
  }):<div style={{margin:"10px", color:"white"}}><h3>Stay tuned!</h3></div>;

  return (
    <div style={{ padding: "16px" }}>
      {/* Search Bar */}
      <Search
        placeholder="Search events..."
        enterButton
        size="large"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} 
        onSearch={(value) => setSearchTerm(value)}
        style={{ marginBottom: 24 }}
      />

      <div>

        {/* <Switch 
          checked={showOnlyMyEvents}
          onChange={handleSwitchChange}
          checkedChildren="Show only my events" 
          unCheckedChildren="Show everyone's events" 
        /> */}

        { marker?(
          <Tag
            closable
            onClose={(e) => {
              e.preventDefault();
              window.location='/events';
            }}
          >
            marker "{marker}"
          </Tag>
        ):null }


        { searchTerm?(
           <Tag
           closable
           onClose={(e) => {
             e.preventDefault();
             setSearchTerm('');
           }}
         >
           Sarch "{searchTerm}"
         </Tag>
        ):null }
      </div>

     
        {/* Ongoing Events Tab */}
        <section key="ongoing" style={{borderBottom:"1px solid white"}}>
          <Title style={{color:"white"}}>Ongoing</Title>
          <InfiniteScroll
            dataLength={events.ongoing.length}
            next={loadMoreEvents}
            hasMore={hasMore}
            loader={<Spin style={{ display: "block", margin: "20px auto" }} />}
            endMessage={<p style={{ textAlign: "center", color:"blue" }}> .</p>}
          >
            <Row gutter={[16, 16]}>{renderEventCards(events.ongoing)}</Row>
          </InfiniteScroll>
        </section>

        {/* Upcoming Events Tab */}
        <section key="upcoming" style={{borderBottom:"1px solid white"}}>
        <Title style={{color:"white"}}>Upcoming</Title>
        <p>
          If you want to stream your performance via existing surveilance cameras and reclaim public space, get in touch with <Link style={{color:"yellow", textDecoration:"auto"}} to={"/contact"}>smaller-than-a-pixel</Link> for a collaboration.
        </p>
        <InfiniteScroll
            dataLength={events.upcoming.length}
            next={loadMoreEvents}
            hasMore={hasMore}
            loader={<Spin style={{ display: "block", margin: "20px auto" }} />}
            endMessage={<p style={{ textAlign: "center", color:"red" }}> .</p>}
          >
            <Row gutter={[16, 16]}>{renderEventCards(events.upcoming)}</Row>
          </InfiniteScroll>
        </section>


        {/* Past Events Tab */}
        <section key="archive">
        <Title style={{color:"white"}}>Archive</Title>
        <InfiniteScroll
            dataLength={events.archive.length}
            next={loadMoreEvents}
            hasMore={hasMore}
            loader={<Spin style={{ display: "block", margin: "20px auto" }} />}
            endMessage={<p style={{ textAlign: "center", color:"yellow"}}> . </p>}
          >
            <Row gutter={[16, 16]}>{renderEventCards(events.archive)}</Row>
          </InfiniteScroll>
        </section>

    </div>
  );
};

export default Events;
